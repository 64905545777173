import "./css/reset.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/layout.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/content.css"
import "./css/animations.css"
import "./css/admin.css"
import "./css/variables.css"
import "./css/colors.css"
import "./css/gamblers.css"
import "./css/counseling-info.css"

import { onReady } from "./js/utils/dom"
import { initSubmenu, initToggleNav } from "./js/components/nav"
import { drawHeadingVectors } from "./js/components/draw-heading-vectors"
// import { initGamblerAnmiations } from "./js/components/gambler-animations"

// window.htmx = require("htmx.org")

// onReady(initRandomColors)
onReady(initToggleNav)
onReady(initSubmenu)
onReady(drawHeadingVectors)
// onReady(initGamblerAnmiations)
